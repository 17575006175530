import React, { useEffect, useState } from 'react';
import Image from 'next/image';
import Link from 'next/link';
import { i18n, useTranslation } from 'next-i18next';
import RoutesEnum from '@/src/enums/routes.enum';
import AppNavigationMobileMenu from '@/components/App/Navigation/AppNavigationMobileMenu';
import useWindowSize from '@/src/hooks/useWindowSize';
import { isLoggedInAdmin } from '@/src/util/auth';
import LanguageSwitcher from '@/components/common/LanguageSwitcher';
import { DirUtils } from '@/src/util/dir';
import ClientOnlyComponent from '@/components/ClientOnlyComponent';
import { LandingPageNavItems } from '../../navbar/navbarLists';
import HoloButton from '../../common/Button/HoloButton';
import HoloIcon from '../../common/Icon/HoloIcon';

const AppNavigation = () => {
  const { t } = useTranslation('app-common');

  const [isMenuVisible, setIsMenuVisible] = useState(false);

  const { isXSmall, isSmall, isMedium, isLarge, isXlarge } = useWindowSize();
  useEffect(() => {
    if (isMedium && (isMedium || isLarge || isXlarge)) {
      setIsMenuVisible(false);
    }
  }, [isXlarge, isSmall, isXSmall, isMedium, isLarge]);

  const isLoggedIn = isLoggedInAdmin();

  const isRtl = DirUtils.isRtl(i18n?.language);
  useEffect(() => {
    document.body.style.overflow = isMenuVisible ? 'hidden' : 'auto';
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isMenuVisible]);

  return (
    <div className="sticky top-0 z-[999] m-auto flex flex-col justify-center bg-black">
      <div className="flex h-[86px] justify-center  pb-0  md:h-[120px] md:pb-5">
        <div className=" flex w-full max-w-6xl items-center px-6  md:items-end xl:px-0">
          <div className="flex flex-col">
            <Link href={RoutesEnum.MAIN}>
              <Image
                className="cursor-pointer"
                alt="holo-logo"
                priority
                width={75}
                height={75}
                src="/assets/Holo_Logo_white.svg"
              />
            </Link>
          </div>

          <div className="flex-end w-full gap-4">
            <div className="flex h-full max-h-[38px] items-center justify-end gap-3 sm:col-span-2 sm:justify-end sm:gap-6">
              <div className="hidden md:block">
                <LanguageSwitcher />
              </div>
              <div className="h-4 border-l border-[#1A1A1A]"></div>

              <ClientOnlyComponent>
                {isLoggedIn && (
                  <div className="mx-4 hidden cursor-pointer items-center justify-between gap-6 md:flex">
                    <Link href={RoutesEnum.PROFILE}>
                      <span className=" text-sm text-white">
                        {t('profile')}
                      </span>
                    </Link>
                    <div className="h-4 border-l border-[#1A1A1A] "></div>
                  </div>
                )}
              </ClientOnlyComponent>

              <ClientOnlyComponent>
                {!isRtl && (
                  <Link
                    href={isLoggedIn ? RoutesEnum.LOGOUT : RoutesEnum.LOGIN}
                  >
                    <div className="mx-4 hidden cursor-pointer items-center gap-1 md:flex">
                      <span>
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 24 24"
                          fill="white"
                        >
                          <path d="M7 8C7 5.23858 9.23858 3 12 3C14.7614 3 17 5.23858 17 8C17 10.7614 14.7614 13 12 13C9.23858 13 7 10.7614 7 8ZM12 11C13.6569 11 15 9.65685 15 8C15 6.34315 13.6569 5 12 5C10.3431 5 9 6.34315 9 8C9 9.65685 10.3431 11 12 11Z" />
                          <path d="M6.34315 16.3431C4.84285 17.8434 4 19.8783 4 22H6C6 20.4087 6.63214 18.8826 7.75736 17.7574C8.88258 16.6321 10.4087 16 12 16C13.5913 16 15.1174 16.6321 16.2426 17.7574C17.3679 18.8826 18 20.4087 18 22H20C20 19.8783 19.1571 17.8434 17.6569 16.3431C16.1566 14.8429 14.1217 14 12 14C9.87827 14 7.84344 14.8429 6.34315 16.3431Z" />
                        </svg>
                      </span>
                      <p className="text-sm text-white">
                        {t(isLoggedIn ? 'Logout' : 'Login')}
                      </p>
                    </div>
                  </Link>
                )}
              </ClientOnlyComponent>

              <div className="">
                <ClientOnlyComponent>
                  <Link
                    href={
                      isLoggedIn
                        ? RoutesEnum.DASHBOARD
                        : RoutesEnum.MORTGAGE_PRODUCTS_SERVICES
                    }
                  >
                    <HoloButton
                      theme="white"
                      size="sm"
                      text={t(isLoggedIn ? 'Dashboard' : 'GetStarted')}
                      extraClasses={`whitespace-pre ${
                        !isLoggedIn
                          ? DirUtils.getLang(i18n?.language) +
                            '-get-started-navbar'
                          : ''
                      }`}
                    />
                  </Link>
                </ClientOnlyComponent>
              </div>

              <button
                className="flex lg:hidden"
                onClick={() => {
                  setIsMenuVisible(!isMenuVisible);
                }}
              >
                <HoloIcon
                  id={isMenuVisible ? 'close_big' : 'menu'}
                  className="block h-6 w-8 fill-white"
                />
              </button>
            </div>

            <div className="mb-4 mt-3 hidden h-[1px] w-full bg-[#1A1A1A] md:block" />

            <div className=" hidden h-full items-center justify-between px-6 sm:col-span-9 lg:flex ">
              {LandingPageNavItems.map((e) => {
                return (
                  <Link key={e.href} href={e.href}>
                    <a className="cursor-pointer whitespace-pre text-xs font-medium text-white hover:underline hover:underline-offset-8 md:text-sm">
                      {t(e.label)}
                    </a>
                  </Link>
                );
              })}
            </div>

            <div className="block md:hidden"></div>
          </div>
        </div>
      </div>
      {isMenuVisible && <AppNavigationMobileMenu />}
    </div>
  );
};

export default AppNavigation;
