import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import React from 'react';
import { Text } from '@chakra-ui/react';
import { DirUtils } from '@/src/util/dir';

const LanguageSwitcher = ({ id }: { id?: string }) => {
  const { i18n } = useTranslation('');
  const router = useRouter();
  const isRtl = DirUtils.isRtl(i18n?.language);
  const OtherMenuTitle = isRtl ? 'English' : 'العربية';

  const { pathname, asPath, query } = router;
  const updateLocal = (event) => {
    event.preventDefault();
    const newLocal = isRtl ? 'en' : 'ar';
    router.push({ pathname, query }, asPath, {
      locale: newLocal,
      shallow: false,
      unstable_skipClientCache: true,
      scroll: false,
    });
  };

  return (
    <>
      <a
        href={`${isRtl ? '/en' : '/ar'}`}
        onClick={(e) => updateLocal(e)}
        id={id}
      >
        <Text
          fontSize="16px"
          py=".5rem"
          fontWeight={400}
          paddingRight="20px"
          fontFamily="Lexend"
          lineHeight="22.4px"
          color="white"
          mixBlendMode="difference"
          cursor="pointer"
          pr="0"
          _hover={{
            textDecoration: 'none',
            color: 'white',
            borderBottom: '1px solid white',
          }}
        >
          {OtherMenuTitle}
        </Text>
      </a>
    </>
  );
};

export default LanguageSwitcher;
