import { useState, useEffect } from 'react';

const useWindowSize = () => {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState<{
    width: number;
    height: number;
  }>({
    width: undefined,
    height: undefined,
  });

  function handleResize() {
    // Set window width/height to state
    setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  }
  useEffect(() => {
    // only execute all the code below in client side
    if (typeof window !== 'undefined') {
      // Handler to call on window resize

      // Add event listener
      window.addEventListener('resize', handleResize);

      // Call handler right away so state gets updated with initial window size
      handleResize();

      // Remove event listener on cleanup
      return () => window.removeEventListener('resize', handleResize);
    }
  }, []); // Empty array ensures that effect is only run on mount

  return {
    ...windowSize,
    isXSmall: windowSize.width < 480,
    isSmall: windowSize.width < 640 && windowSize.width > 480,
    isMedium: windowSize.width < 768 && windowSize.width > 640,
    isLarge: windowSize.width < 1024 && windowSize.width > 768,
    isXlarge: windowSize.width > 1024,
    pageLoading: windowSize.width === undefined,
    isMinXsmall: windowSize.width >= 0,
    isMinSmall: windowSize.width >= 480,
    isMinMedium: windowSize.width >= 640,
    isMinLarge: windowSize.width >= 768,
    isMinXlarge: windowSize.width >= 1024,
  };
};

export default useWindowSize;
